import React, { useEffect, useState } from 'react'
import { DataTable } from '../data-table'
import DMV from './../../../assets/images/DMV_Logo.png'
import { NavLink, useParams } from 'react-router-dom'
import { DVMDetails } from '../../../app/model/dvm-details';
import { UserResponse } from '../../../app/model/user-info';
import { GET_SYSTEM_PARAMS } from '../../../app/services/system-params.service';

interface Props {
  paymentInformation?: boolean;
  paymentDetails?: boolean;
  vehicleData?: DVMDetails[];
  onSubmit?: () => void;
  termsOfUseStatus?: boolean;
}

export const ShippingAddressVehicleDetails: React.FC<Props> = (props: Props) => {
  const params = useParams();
  const [DMVDetails, setDMVDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const rightContent = [
    {
      heading: 'INSTANT REGISTRATION CARD',
      subHeading: 'Download your new registration card instantly.'
    },
    {
      heading: 'EXPEDITED DMV PROCESSING',
      subHeading: 'Instant digital registration with guaranteed overnight shipping options.'
    },
    {
      heading: 'ANYTIME FEE PAYMENT',
      subHeading: 'Pay your fees online from your mobil or desktop device'
    },
    {
      heading: 'ANYWHERE SHIPPING',
      subHeading: 'Ship to alternate address fi required.'
    },
    {
      heading: 'CUSTOMER SUPPORT',
      subHeading: 'DMV Experts are standing by to help.'
    }
  ];


  useEffect(() => {
    window.scrollTo(0, 0);
    getSystemParams();
  }, []);

  const onContinue = () => {
    if (props?.onSubmit) {
      props?.onSubmit();
    }
  }

  const getSystemParams = () => {
    GET_SYSTEM_PARAMS({ code: 'DMV_LOGO' }).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          if (res?.data?.data?.length > 0) {
            setDMVDetails(res?.data?.data[0]);
          }
          setLoading(false);
        }
      }
    });
  }

  return (
    <div className='col-lg-12 mx-md-auto mt-lg-5'>
      <DataTable vehicleData={props?.vehicleData || []} isUserCart={props?.paymentDetails} />
      {!props?.paymentInformation && !props?.paymentDetails && (
        <div className='col-12 d-block d-lg-none text-end mt-4 mb-5'>
          <button className='px-3 fs-5 rounded-1 border-0 primary-bg primary-button fw-500' onClick={onContinue}>CONTINUE</button>
        </div>
      )}
      {(props?.paymentInformation || props?.paymentDetails) && (
        <div className='col-md-12 d-block d-lg-none col-lg-6 order-1 order-lg-2 mt-3 mb-3 mb-lg-0 d-sm-flex justify-content-between px-4'>
          <div className='mb-4 mb-md-0'>
            <NavLink to={`/add-another-vehicle/${params?.user_uuid}/${params?.address_uuid}`}>
              <button className='px-3 fs-5 rounded-1 border-0 primary-bg primary-button_custom_btn fw-500'>BACK</button>
            </NavLink>
          </div>
          <div>
            <button className='px-3 fs-5 rounded-1 border-0 primary-bg primary-button fw-500' disabled={props?.termsOfUseStatus} onClick={onContinue}>PROCESS ORDER</button>
          </div>
        </div>
      )}
      <div className='my-3 pb-xl-1 mt-5'>
        <h2 className='text-center fw-bold fs-36 font-Oswald ls-1'>Why Simple Tags?</h2>
      </div>
      {rightContent.map((data: any) => {
        return <>
          <ul className='col-md-12 ms-lg-2 text-start m-0'>
            <li className='primary-color fs-22 fw-bold text-start'>{data?.heading}</li>
          </ul>
          <div className='custom-gray fs-15 mb-2 fw-500 ps-3'>{data?.subHeading}</div>
        </>
      })}


      {/* < ul className='col-md-12 ms-lg-2 text-start pb-2 pb-xl-4'>
        <li className='primary-color fs-22 fw-bold text-start'>INSTANT REGISTRATION CARD</li>
        <div className='custom-gray fs-15 mb-2 fw-500'>Download your new registration card instantly.</div>
        <li className='primary-color fs-22 fw-bold'>EXPEDITED DMV PROCESSING</li>
        <div className='custom-gray fs-15 mb-2 fw-500'>Instant digital registration with guaranteed overnight shipping options.</div>
        <li className='primary-color fs-22 fw-bold'>ANYTIME FEE PAYMENT</li>
        <div className='custom-gray fs-15 mb-2 fw-500'>Pay your fees online from your mobil or desktop device</div>
        <li className='primary-color fs-22 fw-bold'>ANYWHERE SHIPPING</li>
        <div className='custom-gray fs-15 mb-2 fw-500'>Ship to alternate address fi required.</div>
        <li className='primary-color fs-22 fw-bold'>CUSTOMER SUPPORT</li>
        <div className='custom-gray fs-15 mb-2 fw-500'>DMV Experts are standing by to help.</div>
      </ul> */}
      {/* <div className='col-12 d-sm-flex justify-content-start'>
        <div className='d-flex justify-content-center ps-2'>
          <img src={DMV} alt="dmv" className='shipping-dmv-image' />
        </div>
        <div className='d-lg-flex align-items-center'>
          <div className='mt-3'>
            <h4 className='ms-md-4 mb-0 secondary-color text-center text-md-start font-Oswald'>DMV Delegated Provider</h4>
            <div className='secondary-color text-center text-md-start fs-14 font-Oswald'>Officially Entrusted by the CA Department of Motor Vehicles</div>
          </div>
        </div>
      </div> */}
      <div className='col-12 d-sm-flex justify-content-start mt-4'>
        <div className='d-flex justify-content-center ps-5'>
          <img src={DMVDetails?.value?.dmv_logo || DMV} alt="dmv" className='shipping-dmv-image' />
        </div>
        <div className='d-lg-flex align-items-center'>
          <div className='mt-3'>
            <h4 className='mb-0 secondary-color text-start font-Oswald'>{DMVDetails?.value?.title ? DMVDetails?.value?.title : 'DMV Delegated Provider'}</h4>
            <div className='secondary-color text-center text-md-start fs-14 font-Oswald'>{DMVDetails?.value?.sub_title ? DMVDetails?.value?.sub_title : 'Officially Entrusted by the CA Department of Motor Vehicles'}</div>
          </div>
        </div>
      </div>
    </div >
  )
}
