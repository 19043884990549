import React, { useEffect, useState } from 'react'
import ReplaceTag from './../../../assets/images/our-services-one.png';
import ReplaceLostTitle from './../../../assets/images/our-services-two.jpg';
import AutoInsurance from './../../../assets/images/our-services-three.png';
import AddSpecialityPlate from './../../../assets/images/our-service-four.jpg';
import States from './../../../components/common-components/states.json';
import { CustomMultiSelect } from '../../../components/common-components/custom-drop-down';
import { NavLink, useNavigate } from 'react-router-dom';
import { ServiceTypeData, ServiceTypeResponse } from '../../../app/model/service-types';
import { Loader } from '../../../components/loader';
import { GET_SERVICE_TYPE } from '../../../app/services/service-type.service';
import { CommonDropdownOption } from '../../../app/model/common';
import Sates from '../../../app/utils/state.json'
import toast from 'react-hot-toast';


export const OurServices = () => {
    const [statesData, setStatesData] = useState<CommonDropdownOption[]>(States);
    const [selectedData, setSelectedData] = useState<string[]>([]);
    const [serviceTypes, setServiceTypes] = useState<ServiceTypeData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState({
        limit: 100,
        page: 0,
    });
    const navigation = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSelectionChange = (selectedOptions: string[]) => {
        setSelectedData(selectedOptions);
    };

    useEffect(() => {
        getServiceTypes();
    }, [])

    const getServiceTypes = () => {
        setLoading(true);
        GET_SERVICE_TYPE(pageCount).then((res: ServiceTypeResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    res.data.data = res?.data?.data?.filter((element: any) => element?.status === 1)
                    res?.data?.data.forEach((element: ServiceTypeData) => {
                        element.image = ReplaceTag;
                        element.service_description = "If you already renewed your registration, but need a replacement sticker and/or license plate, order one online with SimpleTags!";
                        if (element.name.toLowerCase().includes("renew")) {
                            element.replace_title = "Renew";
                        } else if (element.name.toLowerCase().includes("replace")) {
                            element.replace_title = "Replace in";
                        } else if (element.name.toLowerCase().includes("transfer")) {
                            element.replace_title = "Transfer";
                        } else {
                            element.replace_title = "Upgrade in";
                        }
                    });
                    setServiceTypes([...res?.data?.data]);
                    setLoading(false);
                }
            }
        });
    }
    const onGo = (service: ServiceTypeData) => {
        sessionStorage.setItem('selectedServiceType', service?.uuid);
        navigation(`/`);
    }

    return (
        <div className='container-fluid'>
            <div className='row my-3'>
                <Loader LoadingReducer={loading} />
                <div className='col-md-12 px-lg-5 mx-auto'>
                    <h4 className='fw-bold font-Oswald my-3 fs-40 ls-1 mb-4'>OUR SERVICES</h4>
                    <div className='row'>
                        {serviceTypes?.map((service: ServiceTypeData, index: number) => (
                            <div className='col-md-4 my-2 text-center' key={index}>
                                <div className='border rounded-2 border-dark service-card'>
                                    <div className='mt-2 mb-1'>
                                        <img src={service?.image} className='our-services-image' />
                                    </div>
                                    <div className='fw-bold'>{service?.name}</div>
                                    <div className='px-5 mb-4 fs-14'>{service?.service_description}</div>
                                    <div className='col-md-12 border-top border-dark'>
                                        <div className='row'>
                                            <div className='col-4 text-start ms-2 border-end border-dark d-flex align-items-center'>{`${service?.replace_title} : `}</div>
                                            <div className='col-6 d-flex align-items-center border-end border-dark  border-0  px-0'>
                                                {/* <select className='form-select border-0' value={'California'}>
                                                    <option value="">state</option>
                                                    {statesData?.map((state: CommonDropdownOption, index: number) => (
                                                        <option value={state?.value} key={index}>{state?.label}</option>
                                                    ))}
                                                </select> */}
                                                <select name="state" className='form-control custom-input bg_dark_gray' value={'CA'}  >
                                                    <option value="" disabled>Select Sate</option>
                                                    {Sates?.map((data: any) => { return <option value={data?.id}>{data?.text}</option> })}
                                                </select>
                                                {/* <CustomMultiSelect optionsList={statesData} customPlaceholder={"State"}
                                                    onSelectionChange={handleSelectionChange} /> */}
                                            </div>
                                            <div className='col-1 d-flex align-items-center text-center cursor-pointer' onClick={() => onGo(service)}>Go</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
