import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GET_USER_SHIPPING_ADDRESS, UPDATE_USER_SHIPPING_ADDRESS } from '../../app/services/user-address-shipping.service';
import { USER_CART } from '../../app/services/user-cart.service';
import { Loader } from '../../components/loader';
import { ShippingAddressForm, ShippingAddressResponse, ShippingAddressResponseData } from '../../app/model/shipping-address';
import { CartPayload } from '../../app/model/cart';
import { CommonResponse } from '../../app/model/common';
import axios from 'axios';
import appConfig from '../../config/constant';
import toast from 'react-hot-toast';
import { GET_USPS_ADDRESS } from '../../app/services/usps.service';


export const AddressConfirmation: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAddress, setSelectedAddress] = useState<string>('entered');
    const [uspsAddressData, setUspsAddressData] = useState<any>();
    const [uspsAddressError, setUspsAddressError] = useState<boolean>(false);
    const [parsedAddressData, setParsedAddressData] = useState<ShippingAddressForm>({
        is_usps_suggested: false
    });

    const navigation = useNavigate();
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
        getUserShippingAddress();

    }, []);

    const handleRadioChange = (data: string) => {
        setSelectedAddress(data);
    };

    const getUserShippingAddress = () => {
        setLoading(true);
        GET_USER_SHIPPING_ADDRESS({ uuid: params?.address_uuid ? params?.address_uuid : '' }).then((res: ShippingAddressResponseData) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    if (res?.data?.data.length > 0) {
                        setParsedAddressData({ ...res?.data?.data[0] });
                        getUSPSAddress(res?.data?.data[0])
                    }
                    setLoading(false);
                }
            }
        });
    }

    const onNext = () => {
        const preparedData = parsedAddressData
        preparedData.is_usps_suggested = selectedAddress === "usps" ? true : false
        setLoading(true);
        delete preparedData['created_dt'];
        delete preparedData['updated_at'];
        UPDATE_USER_SHIPPING_ADDRESS(preparedData?.uuid ? preparedData?.uuid : '', preparedData).then((res: ShippingAddressResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    setLoading(false);
                }
            }
        });
        const userCart: CartPayload = {
            user_uuid: params?.user_uuid ? params?.user_uuid : '',
            user_vehicle_uuid: params?.vehicle_uuid ? params?.vehicle_uuid : '',
            user_shipping_address_info_uuid: preparedData?.uuid ? preparedData?.uuid : '',
        }
        USER_CART(userCart).then((res: CommonResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    navigation(`/add-another-vehicle/${params?.user_uuid}/${params?.address_uuid}`);
                    setLoading(false);
                }
            }
        });
    }

    const getUSPSAddress = async (address: any) => {

        const data = {
            streetAddress: `${address?.street_address} ${address?.unit}`,
            city: address?.city,
            state: address?.state
        }
        setLoading(true);
        GET_USPS_ADDRESS(data).then((res: any) => {
            if (res?.status === "fail") {
                // toast.error(res.error?.data?.message || 'Invalid Details', { position: 'top-right' });
                setUspsAddressError(true);
                setLoading(false);
            } else {
                if (res) {
                    setUspsAddressData(res?.data?.address)
                    setLoading(false);
                }
            }
        });

    }

    return (
        <div className='primary-bg mt-5 mt-md-0'>
            <div className='container'>
                <div className="row">
                    <Loader LoadingReducer={loading} />
                    <div className='col-md-12 primary-bg mx-auto'>
                        <div className='row py-4 py-md-4 p-md-3'>
                            <div className='col-11 bg-white py-5 p-md-3 mx-auto rounded-4 border-0 text-center'>
                                <div className='px-3 px-md-5 pt-3'>
                                    <h4 className='fw-bold font-Oswald ls-1 fs-36'>ADDRESS CONFIRMATION</h4>
                                    <h3 className='fw-500 my-4 mt-4 '>USPS adjusted the shipping address entered.</h3>
                                    <div className='fw-500'>Please verify and select the address for this order, then click "<span className='fw-600'>NEXT</span>" .</div>
                                    <div className='fw-500'>If you still need to modify the address, click "<span className='fw-600'>BACK</span>".</div>
                                </div>
                                <div className='d-flex justify-content-center mt-4 mb-4'>
                                    <div className="form-check cursor-pointer">
                                        <input className="form-check-input cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                                            checked={selectedAddress === 'entered'}
                                            onChange={(e) => handleRadioChange("entered")}
                                        />
                                        <label className="form-check-label fw-500 text-dark cursor-pointer" htmlFor="flexRadioDefault1"><b>ADDRESS ENTERED</b></label>
                                        <div className='ps-4 mt-1 ms-1 address_custom_width'>
                                            <div className='text-start'>{`${parsedAddressData?.first_name}  ${parsedAddressData?.last_name}`}</div>
                                            <div className='text-start'>{`${parsedAddressData?.street_address}, ${parsedAddressData?.unit}`}</div>
                                            <div className='text-start'>{`${parsedAddressData?.city}`}</div>
                                            <div className='text-start'>{`${parsedAddressData?.state}, ${parsedAddressData?.zip_code}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center mt-2 mb-5'>
                                    <div className="form-check  cursor-pointer">
                                        <input className="form-check-input cursor-pointer" disabled={uspsAddressError} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                            checked={selectedAddress === 'usps'}
                                            onChange={(e) => handleRadioChange("usps")}
                                        />
                                        <label className="form-check-label fw-500 cursor-pointer" htmlFor="flexRadioDefault2">USPS SUGGESTION</label>
                                        {!uspsAddressError ? <div className='ps-4 mt-1 ms-1 address_custom_width'>
                                            <div className='text-start'>{uspsAddressData?.streetAddress}</div>
                                            <div className='text-start'>{uspsAddressData?.city}</div>
                                            <div className='text-start'>{uspsAddressData?.state}, {uspsAddressData?.ZIPCode}</div>
                                        </div> : <div className='ps-4 mt-1 ms-1'><div className='text-start ps-5'>no matching address found</div></div>}
                                    </div>
                                </div>
                                <div className='row mt-5 pt-5 mb-lg-4'>
                                    <div className='col-6 text-start text-md-end'>
                                        <NavLink to={`/shipping-address/${params?.user_uuid}/${params?.vehicle_uuid}`}><button className='px-3 rounded-1 primary-bg primary-button_second fw-500 ms-md-3'>BACK</button></NavLink>
                                    </div>
                                    <div className='col-6 text-end text-md-start'>
                                        <button className='px-3 rounded-1 border-0 primary-bg primary-button fw-500' onClick={onNext}>NEXT</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
