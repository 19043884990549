import React, { useState } from 'react';
import './tooltip.css'; // Add your styles here

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  return (
    <div 
      className="tooltip-container" 
      onMouseEnter={showTooltip} 
      onMouseLeave={hideTooltip}
    >
      {children}
      {isVisible && 
      <div className="tooltip-box">{text}</div>
      }
    </div>
  );
};

export default Tooltip;
