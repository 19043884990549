import React, { useEffect, useState } from 'react'
import { DataTable } from '../../components/common-components/data-table'
import { NavLink, useParams } from 'react-router-dom'
import { Loader } from '../../components/loader';
import { GET_USER_CART } from '../../app/services/user-cart.service';
import { UPDATE_USER_SHIPPING_ADDRESS } from '../../app/services/user-address-shipping.service';
import { DELETE_USER_ORDER } from '../../app/services/user-orders.service';
import { CommonResponse, DynamicObject } from '../../app/model/common';
import { ShippingAddressForm, ShippingAddressResponse } from '../../app/model/shipping-address';
import { CartResponse, CartResponseData } from '../../app/model/cart';
import Sates from '../../app/utils/state.json'
import toast from 'react-hot-toast';
import { formatPhoneNumber, getOnlyNumbers } from '../../app/utils/common-functions';

export const AddAnotherVehicle: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [userCartData, setUserCartData] = useState<any>([]);
  const params = useParams();
  const [isOwner, setIsOwner] = useState(true);
  const [selectedCartData, setSelectedCartData] = useState<CartResponse>();
  const [shippingAddressData, setShippingAddressData] = useState<ShippingAddressForm>({
    first_name: "",
    last_name: "",
    city: "",
    state: "",
    zip_code: "",
    email: "",
    phone_no: "",
    is_owner: isOwner,
    company_name: "",
    street_address: "",
    unit: "",
    is_overnight_shipping: true,
  })
  const [formErrors, setFormErrors] = useState<DynamicObject>({});
  const [totalPrice, setTotalPrice] = useState<string>();
  const orderedKeys = [
    'dmv_registration_fee',
    'ca_dmv_bpa_fee',
    'simple_tags_service_fee',
    'shipping_&_handling',
    'convenience_fee',
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getUserCartData();
  }, [])

  const getUserCartData = () => {
    setLoading(true);
    GET_USER_CART({ user_uuid: params?.user_uuid }).then((res: any) => {
      if (res?.status === "fail") {
        toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
        setLoading(false);
      } else {
        if (res) {
          setUserCartData([...res?.data?.data]);
          const sum = res?.data?.data.reduce((accumulator: any, current: any) => {
            return accumulator + parseFloat(current.final_price);
          }, 0);
          const formattedSum = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sum);
          setTotalPrice(formattedSum);
          setLoading(false);
        }
      }
    });
  }

  const onShowDeletePopup = (data: CartResponse) => {
    setSelectedCartData(data)
    setShowDeletePopup(true);
  }

  const onDeleteUserConfirmation = () => {
    setLoading(true);
    DELETE_USER_ORDER(selectedCartData?.uuid ? selectedCartData?.uuid : '').then((res: CommonResponse) => {
      if (res?.status === "fail") {
        toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
        setLoading(false);
      } else {
        if (res) {
          getUserCartData();
          setShowDeletePopup(false);
          setLoading(false);
        }
      }
    });
  }

  const onShowEditPopup = (data: CartResponse) => {
    data.user_shipping_address.phone_no = data?.user_shipping_address?.phone_no ? formatPhoneNumber(data?.user_shipping_address?.phone_no) : '';
    setShippingAddressData({ ...data?.user_shipping_address });
    setSelectedCartData(data);
    setShowEditPopup(true);
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setShippingAddressData((prevData: ShippingAddressForm) => ({
      ...prevData,
      [name]: value,
    }));

    setFormErrors((prevErrors: DynamicObject) => ({
      ...prevErrors,
      [name]: validateSpecificField(name, value),
    }));
  }

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    // Apply formatting if the field is the mobile number
    let formattedValue = value;
    if (name === "phone_no") {
      // Remove any non-numeric characters
      const numericValue = value.replace(/\D/g, '');

      // Format as (xxx) xxx-xxxx if length is 10
      if (numericValue.length >= 10) {
        formattedValue = numericValue.replace(
          /^(\d{3})(\d{3})(\d{4})$/,
          '($1) $2-$3'
        );
      } else {
        formattedValue = numericValue; // Keep the unformatted value if length is less than 10
      }
    }
    setShippingAddressData((prevData: ShippingAddressForm) => ({
      ...prevData,
      [name]: formattedValue,
    }));
    setFormErrors((prevErrors: DynamicObject) => ({
      ...prevErrors,
      [name]: validateSpecificField(name, formattedValue),
    }));
  };

  const handleChangeOvernight = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShippingAddressData({ ...shippingAddressData, is_overnight_shipping: e.target.checked })
  }

  const handleChangeCompetitively = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newIsOwner = e.target.value === 'yes';
    setIsOwner(newIsOwner);
    setShippingAddressData({ ...shippingAddressData, is_owner: newIsOwner })
  };

  const validateSpecificField = (name: string, value: string) => {
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,6}$/;
    const mobileRegEx = /^(\+1|1)?\s*\(?\d{3}\)?\s*-?\s*\d{3}\s*-?\s*\d{4}$/; // /^\(\d{3}\) \d{3}-\d{4}$/;
    if (name === "email") {
      if (!value) {
        return "Please enter email"
      } else if (!emailRegEx.test(value)) {
        return "Please enter a valid email address";
      }
    }
    if (name === "phone_no") {
      if (!value) {
        return "Please enter mobile phone"
      } else if (!mobileRegEx.test(value)) {
        return "Please enter a valid mobile phone";
      }
    }
    return "";
  }

  const validateForm = () => {
    const errors: DynamicObject = {};
    if (!shippingAddressData?.first_name) {
      errors.first_name = "Please enter first name"
    }

    if (!shippingAddressData?.last_name) {
      errors.last_name = "Please enter last name"
    }

    if (!shippingAddressData?.street_address) {
      errors.street_address = "Please enter street address"
    }

    // if (!shippingAddressData?.unit) {
    //   errors.unit = "Please enter apt / unit / suite"
    // }

    if (!shippingAddressData?.city) {
      errors.city = "Please enter city"
    }

    if (!shippingAddressData?.state) {
      errors.state = "Please enter state"
    }
    if (!shippingAddressData?.zip_code) {
      errors.zip_code = "Please enter zip code"
    }
    if (!shippingAddressData?.email) {
      errors.email = "Please enter email"
    }
    if (!shippingAddressData?.phone_no) {
      errors.phone_no = "Please enter mobile phone"
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onUpdate = () => {
    const isValid = validateForm();
    if (isValid) {
      setLoading(true);
      const address = shippingAddressData;
      address.phone_no = address?.phone_no ? getOnlyNumbers(address?.phone_no) : '';
      delete address['created_dt'];
      delete address['updated_at'];
      UPDATE_USER_SHIPPING_ADDRESS(shippingAddressData?.uuid ? shippingAddressData?.uuid : '', address).then((res: ShippingAddressResponse) => {
        if (res?.status === "fail") {
          toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
          setLoading(false);
        } else {
          if (res) {
            setLoading(false);
            setShowEditPopup(false);
            getUserCartData();
          }
        }
      });
    }
  }

  const transformString = (str: string) => {
    // List of words to keep in uppercase
    const exceptions = ['DMV', 'CA', 'BPA'];

    return str
      .split('_') // Split the string by underscores
      .map(word => {
        // Check if the word should be in uppercase
        if (exceptions.includes(word.toUpperCase())) {
          return word.toUpperCase();
        }
        // Capitalize the first letter of the word
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' '); // Join the words with a space
  };

  const formatCurrency = (value: any) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);

  return (
    <div className='primary-bg mt-xl-2' style={{ height: "calc(100vh - 100px)" }}>
      <div className='container '>
        <Loader LoadingReducer={loading} />
        <div className='row mx-0'>
          <div className='col-md-12 py-4 primary-bg mx-md-auto  pt-3 pt-xl-0  mt-xl-0"'>
            <div className='row py-4 py-md-4 p-md-3'>
              <div className='col-11 col-md-11 bg-white py-3 p-md-3 mx-auto rounded-4 text-center custom_border_2'>
                <div className='row py-lg-4 pb-0 pt-4'>
                  <div className='col-lg-6 px-3 px-md-5'>
                    <div>
                      <h4 className='font-Oswald text-lg-start text-center ls-1 fs-32 fw-bold'>Add Another Vehicle To This Order</h4>
                      <div className='fw-500 text-start my-3 lh-sm'>
                        You can add multiple vehicles to same order. We save each vehicle on this page until you are ready to check out.
                      </div>
                      <div className='fw-500 text-start lh-sm mt-xl-4 pt-xl-1'>
                        If you only have one vehicle for this order, simply click check out to continue.
                      </div>
                    </div>
                    <div className={` d-flex flex-column justify-content-center align-items-center  ${userCartData?.length > 0 ? "my-lg-4 mb-4 pt-lg-0 mt-3" : ""}`}>
                      <NavLink className="text-decoration-none" to="/"><button className='mt-4 px-3 fs-18 rounded border-0 primary-bg primary-button fw-500'>ADD ANOTHER VEHICLE</button></NavLink>
                    </div>
                  </div>
                  <div className='col-lg-6 mt-xl-5 pt-xl-3'>
                    <div className='pb-0'>
                      <DataTable vehicleData={userCartData} isUserCart={true} />
                    </div>
                    {/* <div className='border border-dark mt-0 mb-3'>
                      <div className='text-end fw-bold me-5 py-1'><span className='fs-18'>Total:</span><span className='fs-18'> {totalPrice}</span></div>
                    </div> */}

                    {userCartData?.map((data: any, index: number) => {
                      return <div key={index}>
                        <div className='border border-dark mb-2 mt-3'>
                          <div className='text-center fw-600 py-1 fs-22 font-Oswald'>{data?.user_vehicle?.vehicle_license_plate} - 1 Year Renewal</div>
                        </div>

                        <div className='border border-dark my-2 py-3 custom-gray'>
                          {/* {Object.keys(data?.service_type?.price_config).map(function (key) { */}
                          {orderedKeys.map(function (key) {
                            return <div className='row my-2'>
                              <div className={`col-6 text-end fs-14 ${transformString(key) === 'DMV Registration Fee' ? 'fw-bold' : ''}`}>{transformString(key)}</div>
                              <div className='col-6 text-start fs-14'>{formatCurrency(data?.dmv_deal_details ? data?.dmv_deal_details[key] : data?.service_type?.price_config[key])}</div>
                            </div>
                          })}
                          <div className='row my-2 fw-bold'>
                            <div className='col-6 text-end fs-14'>Subtotal Fees</div>
                            <div className='col-6 text-start fs-14'>{formatCurrency(data?.total_price)}</div>
                          </div>
                          <div className='row my-2'>
                            <div className='col-6 text-end fs-14 fw-bold'>Ship To</div>
                            <div className='col-6 text-start fw-normal fs-14'>
                              <div className='text-start fw-normal'>{`${data?.user_shipping_address?.first_name}  ${data?.user_shipping_address?.last_name}`}</div>
                              <div className='text-start fw-normal'>{`${data?.user_shipping_address?.street_address}${data?.user_shipping_address?.unit ? ", " : ''}${data?.user_shipping_address?.unit}, ${data?.user_shipping_address?.city}`}</div>
                              <div className='text-start fw-normal'>{`${data?.user_shipping_address?.state}, ${data?.user_shipping_address?.zip_code}`}</div>
                            </div>
                          </div>
                          <div className='row mx-2 my-3'>
                            <div className='col-6 text-start text-md-end'>
                              <button className='px-3 primary-button_custom_btn fw-500' onClick={() => onShowEditPopup(data)}>EDIT</button>
                            </div>
                            <div className='col-6 text-end text-md-start'>
                              <button className='px-3 primary-button_custom_btn fw-500' onClick={() => onShowDeletePopup(data)}>DELETE</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                    {userCartData?.length == 0 && (
                      <div className='text-center'>No vehicle's found. Please add vehicle</div>
                    )}
                    {userCartData?.length > 0 && (
                      <div className='row  border-bottom border-top border-gray mt-4 pt-4 mb-2 custom-gray'>
                        <div className='col-6 text-end fw-bold'>Contact info</div>
                        <div className='col-6 text-start'>
                          <div className='text-start'> {userCartData[(userCartData.length - 1)]?.user_shipping_address?.email}</div>
                          <div className='text-start'>{formatPhoneNumber(userCartData[(userCartData.length - 1)]?.user_shipping_address?.phone_no)}</div>
                        </div>
                        <div className="col-6"></div>
                        <div className='col-6 text-start pe-xxl-5 my-2 my-md-4 my-4 my-md-2'>
                          <button className='px-3 fs-18 border-0 primary-bg primary-button_custom_btn fw-500' onClick={() => onShowEditPopup(userCartData[(userCartData.length - 1)])}>EDIT CONTACT INFO</button>
                        </div>
                      </div>
                    )}
                    <div className='col-md-12 my-3 mt-xl-4 text-center'>
                      <NavLink to={`/payment-details/${params?.user_uuid}/${userCartData[0]?.user_cart_uuid}/${params?.address_uuid}`}><button className='px-4 me-xl-5 fs-18 border-0 primary-bg checkout-button fw-500' disabled={userCartData?.length == 0}>CHECKOUT</button></NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showEditPopup && (
            <>
              <div className={`modal modal-custom fade ${showEditPopup ? "show" : "false"}  ${showEditPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header mx-4 px-0">
                      <h1 className="modal-title fs-5 fw-light text-center w-100" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Edit</strong></h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowEditPopup(false)}></button>
                    </div>
                    <div className="modal-body pb-2 px-lg-5 mx-lg-5" style={{
                      height: "calc(100vh - 195px)",
                      overflow: 'auto'
                    }}>
                      <div className="row popup_custom_scroll">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <div className='row my-3'>
                              <div className='col-md-6 my-1 mb-2 mb-lg-3 pe-xl-0'>
                                <label className='form-label fw-bold'>Enter First Name * </label>
                                <input type="text" name="first_name" autoComplete="given-name" className='form-control' defaultValue={shippingAddressData?.first_name} onChange={(e) => handleInput(e)} />
                                {formErrors.first_name && (
                                  <div className="text-danger text-start w-100">{formErrors.first_name} </div>
                                )}
                              </div>
                              <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                <label className='form-label fw-bold'>Enter Last Name * </label>
                                <input type="text" name="last_name" autoComplete="family-name" className='form-control' defaultValue={shippingAddressData?.last_name} onChange={(e) => handleInput(e)} />
                                {formErrors.last_name && (
                                  <div className="text-danger text-start w-100">{formErrors.last_name} </div>
                                )}
                              </div>
                              <div className='col-md-12 my-1 mb-2 mb-lg-3'>
                                <div className='form-label fw-bold'>Are you the registered vehicle's owner? * </div>
                                <div className="">
                                  <input type="radio" id="test3" className='me-2 cursor-pointer' name="is_owner" checked={isOwner === true}
                                    value="yes" onChange={handleChangeCompetitively} />
                                  <label htmlFor="test3">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                  {/* </div>
                                <div className="mt-2"> */}
                                  <input type="radio" id="test" className='me-2 cursor-pointer' name="is_owner" checked={isOwner === false}
                                    value="no" onChange={handleChangeCompetitively} />
                                  <label htmlFor="test">No</label>
                                </div>
                              </div>
                              <div className='col-md-12 my-1 mb-2 mb-lg-3'>
                                <label className='form-label fw-bold'>Company Name (optional)</label>
                                <input type="text" name="company_name" autoComplete="organization" className='form-control' defaultValue={shippingAddressData?.company_name} onChange={(e) => handleInput(e)} />
                                {formErrors.company_name && (
                                  <div className="text-danger text-start w-100">{formErrors.company_name} </div>
                                )}
                              </div>
                              <div className='col-md-6 my-1 mb-2 mb-lg-3 pe-xl-0'>
                                <label className='form-label fw-bold'>Street Address *</label>
                                <input type="text" name="street_address" autoComplete="address-line1" className='form-control' defaultValue={shippingAddressData?.street_address} onChange={(e) => handleInput(e)} />
                                {formErrors.street_address && (
                                  <div className="text-danger text-start w-100">{formErrors.street_address} </div>
                                )}
                              </div>
                              <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                <label className='form-label fw-bold'>Apt / Unit / Suite </label>
                                <input type="text" name="unit" autoComplete="address-line2" className='form-control' defaultValue={shippingAddressData?.unit} onChange={(e) => handleInput(e)} />
                                {formErrors.unit && (
                                  <div className="text-danger text-start w-100">{formErrors.unit} </div>
                                )}
                              </div>
                              <div className='col-md-12 my-1 mb-2 mb-lg-3'>
                                <label className='form-label fw-bold'>City *</label>
                                <input type="text" name="city" autoComplete="address-level2" className='form-control' defaultValue={shippingAddressData?.city} onChange={(e) => handleInput(e)} />
                                {formErrors.city && (
                                  <div className="text-danger text-start w-100">{formErrors.city} </div>
                                )}
                              </div>
                              <div className='col-md-6 my-1 mb-2 mb-lg-3 pe-xl-0'>
                                <label className='form-label fw-bold'>State *</label>
                                <select name="state" className='form-control custom-input' value={shippingAddressData?.state} onChange={(e) => handleInput(e)} >
                                  <option value="" disabled>Select Sate</option>
                                  {Sates?.map((data: any) => { return <option value={data?.id}>{data?.text}</option> })}
                                </select>
                                {/* <input type="text" name="state" className='form-control' defaultValue={shippingAddressData?.state} onChange={(e) => handleInput(e)} /> */}
                                {formErrors.state && (
                                  <div className="text-danger text-start w-100">{formErrors.state} </div>
                                )}
                              </div>
                              <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                <label className='form-label fw-bold'>ZIP Code *</label>
                                <input type="text" name="zip_code" autoComplete="postal-code" maxLength={6} className='form-control' defaultValue={shippingAddressData?.zip_code} onChange={(e) => handleInput(e)} />
                                {formErrors.zip_code && (
                                  <div className="text-danger text-start w-100">{formErrors.zip_code} </div>
                                )}
                              </div>
                              <div className='col-md-6 my-1 mb-2 mb-lg-3 pe-xl-0'>
                                <label className='form-label fw-bold'>Email *</label>
                                <input type="text" name="email" autoComplete="email" className='form-control' defaultValue={shippingAddressData?.email} onChange={(e) => handleInput(e)} />
                                {formErrors.email && (
                                  <div className="text-danger text-start w-100">{formErrors.email} </div>
                                )}
                              </div>
                              <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                <label className='form-label fw-bold'>Mobile Phone *</label>
                                <input type="text" name="phone_no" autoComplete="tel" className='form-control' value={shippingAddressData?.phone_no} onChange={(e) => handleInput(e)} onBlur={(e) => handleBlur(e)} />
                                {formErrors.phone_no && (
                                  <div className="text-danger text-start w-100">{formErrors.phone_no} </div>
                                )}
                              </div>
                              <div className='col-md-12 my-1'></div>
                              <div className='col-md-6 my-1'>
                                <div className="form-check bg_gray p-0 py-1 border-2">
                                  <div className='ms-4 ps-2'>
                                    <input className="form-check-input fw-bold cursor-pointer" name="is_overnight_shipping" type="checkbox" defaultChecked onChange={handleChangeOvernight} id="flexRadioDefault3" />
                                    <label className="form-check-label fw-bold ms-2 my-1" htmlFor="flexRadioDefault3"><b>Add overnight shipping for $39.95</b></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className='px-2 text-center mt-2 pb-4'>
                      <button type="button" className="px-3 border-0 primary-bg primary-button fw-500 mx-3" onClick={onUpdate}>Update</button>
                      {/* <button type="button" className="px-3 border-0 primary-button_second fw-500 " onClick={() => setShowEditPopup(false)}>Cancel</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {showDeletePopup && (
            <>
              <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                <Loader LoadingReducer={loading} />
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content text-start">
                    <div className="modal-header mx-4 px-0">
                      <h1 className="modal-title fs-5 fw-light text-center w-100" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                    </div>
                    <div className="modal-body pb-4">
                      <div className="row px-2">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <p className="f16 p-2 text-center">Are you sure you want to delete <span className='fw-bold '>{selectedCartData?.user_vehicle?.vehicle_license_plate}</span> ?</p>
                          </div>
                        </div>
                      </div>
                      <div className='px-2 text-center'>
                        <button type="button" className="px-3 fs-18 border-0 primary-button fw-500 mx-3" onClick={() => onDeleteUserConfirmation()}>Yes</button>
                        {/* <button type="button" className="px-3 fs-18 border-0 primary-button_second fw-500 " onClick={() => setShowDeletePopup(false)}>No</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
