import React, { useEffect, useRef, useState } from 'react'
import { ShippingAddressVehicleDetails } from '../../components/common-components/shipping-address/shipping-address-vehicle-details'
import CreditCardImages from './../../assets/images/credit-card-images.jpg';
import CVVInfo from './../../assets/images/cvv_question.png';

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../components/loader';
import { USER_CHECKOUT } from '../../app/services/checkout.service';
import { GET_USER_CART } from '../../app/services/user-cart.service';
import { DVMDetails } from '../../app/model/dvm-details';
import { ShippingAddressForm, ShippingAddressResponseData } from '../../app/model/shipping-address';
import axios from 'axios';
import { DynamicObject } from '../../app/model/common';
import appConfig from '../../config/constant'
import toast from 'react-hot-toast';
import { UserResponse } from '../../app/model/user-info';
import { GET_USER } from '../../app/services/user.service';
import { GET_USER_SHIPPING_ADDRESS } from '../../app/services/user-address-shipping.service';
import Sates from '../../app/utils/state.json'
import Tooltip from '../../components/tooltip/tooltip';

export const PaymentDetails = () => {
    const params = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const navigation = useNavigate();
    const [userVehicleInfo, setUserVehicleInfo] = useState<DVMDetails[]>([]);
    const [isShippingAddressExist, setIsShippingAddressExist] = useState<boolean>(false);
    const [isOwner, setIsOwner] = useState(true);
    const [totalPrice, setTotalPrice] = useState<string>();
    const [showErrorPopUp, setShowErrorPopUp] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<string>('');
    const [userDetails, setUserDetails] = useState<any>([]);
    // const [year, setYear] = useState<any>();
    // const [month, setMonth] = useState<any>();
    const year = useRef<any>(null);
    const month = useRef<any>(null);
    const [isCreditCardBillingAddressUseExisting, setIsCreditCardBillingAddressUseExisting] = useState<string>('existing');

    const [paymentDetailsData, setPaymentDetailsData] = useState<any>({
        first_name: "",
        last_name: "",
        city: "",
        state: "",
        credit_card_number: "",
        expiration_year: "",
        expiration_month: "",
        cvv: "",
        zip_code: "",
        email: "",
        phone_no: "",
        street_address: "",
        unit: "",
        renewal_reminders: true,
        terms_and_conditions: true
    })

    const [billingAddress, setBillingAddress] = useState<any>({
        city: "",
        state: "CA",
        zip_code: "",
        street_address: "",
        unit: "",
    })

    const [formErrors, setFormErrors] = useState<DynamicObject>({});
    const [billingAddressFormErrors, setBillingAddressFormErrors] = useState<DynamicObject>({});


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getUserVehicleInfo();
        getUserInfo(params?.user_uuid);
        getUserShippingAddress();
    }, [])

    const handleRadioChange = (data: string) => {
        setIsCreditCardBillingAddressUseExisting(data);
    };

    const getUserShippingAddress = () => {
        setLoading(true);
        GET_USER_SHIPPING_ADDRESS({ uuid: params?.address_uuid ? params?.address_uuid : '' }).then((res: ShippingAddressResponseData) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    if (res?.data?.data.length > 0) {
                        const response = res?.data?.data[0];
                        setPaymentDetailsData({
                            ...paymentDetailsData, city: response?.city, street_address: response?.street_address,
                            unit: response?.unit, zip_code: response?.zip_code, state: response?.state, email: response?.email, phone_no: response?.phone_no, renewal_reminders: true,
                            terms_and_conditions: true, full_name: `${response?.first_name} ${response?.last_name}`
                        })
                    }
                    setLoading(false);
                }
            }
        });
    }

    const getUserVehicleInfo = () => {
        setLoading(true);
        GET_USER_CART({ user_uuid: params?.user_uuid }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    setUserVehicleInfo([...res?.data?.data]);
                    const sum = res?.data?.data.reduce((accumulator: any, current: any) => {
                        return accumulator + parseFloat(current.final_price);
                    }, 0);
                    setTotalPrice(sum);
                    setLoading(false);
                }
            }
        });
    }

    const getUserInfo = (userUuid: any) => {
        setLoading(true);
        GET_USER({ uuid: params?.user_uuid }).then((res: UserResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.data[0]
                    // setPaymentDetailsData({
                    //     ...paymentDetailsData, email: response?.email, renewal_reminders: true,
                    //     terms_and_conditions: true
                    // })
                    setUserDetails({ ...res?.data?.data[0] })
                    setLoading(false);
                }
            }
        });
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === "expiration_year") {
            // setYear(value)
            year.current = value;
        }
        if (name === "expiration_month") {
            month.current = value;
        }
        setPaymentDetailsData((prevData: ShippingAddressForm) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, value),
        }));
    }

    const handleInputAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setBillingAddress((prevData: ShippingAddressForm) => ({
            ...prevData,
            [name]: value,
        }));

        setBillingAddressFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, value),
        }));
        // billingAddress, setBillingAddress
        // billingAddressFormErrors, setBillingAddressFormErrors
    }

    const handleCheckInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setPaymentDetailsData((prevData: ShippingAddressForm) => ({
            ...prevData,
            [name]: checked,
        }));
    }

    const validateSpecificField = (name: string, value: string) => {
        const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{2,6}$/;
        const mobileRegEx = /^\(\d{3}\) \d{3}-\d{4}$/;
        const cardRegEx = /^[0-9]{0,19}$/
        const cvvRegEx = /^\d{3,4}$/;
        const monthRegEx = /^(0[1-9]|1[0-2])$/;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        const yearRegEx = /^\d{4}$/;

        if (name === "email") {
            if (!value) {
                return "Please enter email"
            } else if (!emailRegEx.test(value)) {
                return "Please enter a valid email address";
            }
        }
        if (name === "phone_no") {
            if (!value) {
                return "Please enter mobile phone"
            } else if (!mobileRegEx.test(value)) {
                return "Please enter a valid mobile phone";
            }
        }
        if (name === "credit_card_number") {
            if (!value) {
                return "Please enter credit card number"
            } else if (!cardRegEx.test(value)) {
                return "Please enter valid credit card number"
            }
        }

        if (name === "cvv") {
            if (!value) {
                return "Please enter cvv"
            } else if (!cvvRegEx.test(value)) {
                return "Please enter valid cvv number";
            }
        }

        if (name === "expiration_month") {
            if (!value) {
                return "Please enter expiration month";
            } else if (!monthRegEx.test(value)) {
                return "Please enter valid expiration month";
            }
            if (parseInt(year.current) < currentYear ||
                (parseInt(year.current) === currentYear && parseInt(value) < currentMonth)) {
                return "Expiration date cannot be in the past";
            }
            return ""
        }

        if (name === "expiration_year") {
            setFormErrors((prevErrors: DynamicObject) => ({
                ...prevErrors,
                ['expiration_month']: ""
            }));

            if (!value) {
                return "Please enter expiration year";
            } else if (!yearRegEx.test(value)) {
                return "Please enter a valid expiration year";
            }
            else if (parseInt(month.current) < currentMonth && year.current <= currentYear) {
                setFormErrors((prevErrors: DynamicObject) => ({
                    ...prevErrors,
                    ['expiration_month']: "Expiry month cannot be in the past"
                }));
                return;
            }
            if (parseInt(value) < currentMonth || parseInt(year.current) < currentYear) {
                return `Expiry year cannot be in the past`;
            }
        }

        return "";
    }

    const validatePaymentDetailsForm = () => {
        const errors: DynamicObject = {};
        if (!paymentDetailsData?.first_name) {
            errors.first_name = "Please enter first name"
        }

        if (!paymentDetailsData?.last_name) {
            errors.last_name = "Please enter last name"
        }

        if (!paymentDetailsData?.credit_card_number) {
            errors.credit_card_number = "Please enter credit card number"
        }

        if (!paymentDetailsData?.expiration_month) {
            errors.expiration_month = "Please enter expiration month"
        }

        if (!paymentDetailsData?.expiration_year) {
            errors.expiration_year = "Please enter expiration year"
        }

        if (!paymentDetailsData?.cvv) {
            errors.cvv = "Please enter cvv"
        }

        // if (isCreditCardBillingAddressUseExisting === 'new') {
        //     if (!billingAddress?.street_address) {
        //         billingAddressFormErrors.street_address = "Please enter billing street address"
        //     }

        //     // if (!billingAddress?.unit) {
        //     //     errors.unit = "Please enter apt / unit / suite"
        //     // }

        //     if (!billingAddress?.city) {
        //         billingAddressFormErrors.city = "Please enter city"
        //     }

        //     if (!billingAddress?.state) {
        //         billingAddressFormErrors.state = "Please enter state"
        //     }
        //     if (!billingAddress?.zip_code) {
        //         billingAddressFormErrors.zip_code = "Please enter zip code"
        //     }
        //     setBillingAddressFormErrors(billingAddressFormErrors);
        //     return Object.keys(billingAddressFormErrors).length === 0;
        // }
        // if (!paymentDetailsData?.email) {
        //     errors.email = "Please enter email"
        // }
        // if (!paymentDetailsData?.phone_no) {
        //     errors.phone_no = "Please enter mobile phone"
        // }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const validateAddressDetailsForm = () => {
        const errors: DynamicObject = {};
        if (isCreditCardBillingAddressUseExisting === 'new') {
            if (!billingAddress?.street_address) {
                errors.street_address = "Please enter billing street address"
            }
            if (!billingAddress?.city) {
                errors.city = "Please enter city"
            }

            if (!billingAddress?.state) {
                errors.state = "Please enter state"
            }
            if (!billingAddress?.zip_code) {
                errors.zip_code = "Please enter zip code"
            }
            setBillingAddressFormErrors(errors);
            return Object.keys(errors).length === 0;
        }
    }

    const onProcessOrder = async () => {
        const isValid = validatePaymentDetailsForm();
        const isBillingAddressValid = validateAddressDetailsForm();

        // if (validateAddressDetailsForm()) {

        // }
        if (isValid && paymentDetailsData?.terms_and_conditions && (isBillingAddressValid && isCreditCardBillingAddressUseExisting === 'new' || isCreditCardBillingAddressUseExisting !== 'new')) {
            const orderIds = userVehicleInfo.map((order: any) => order.uuid);
            setLoading(true);
            const payload = {
                user_uuid: params?.user_uuid,
                amount: totalPrice,
                cardname: paymentDetailsData?.first_name || "" + paymentDetailsData?.last_name || "",
                cardnumber: paymentDetailsData?.credit_card_number || "",
                cvv: paymentDetailsData?.cvv || "",
                expmonth: paymentDetailsData?.expiration_month || "",
                expyear: paymentDetailsData?.expiration_year || "",
                zipcode: paymentDetailsData?.zip_code || "",
                email: paymentDetailsData?.email || "",
                description: "",
                renewal_reminders: paymentDetailsData?.renewal_reminders ? 1 : 0,
                terms_and_conditions: paymentDetailsData?.terms_and_conditions ? 1 : 0,
                orders: orderIds,
                billing_address: {
                    first_name: paymentDetailsData?.first_name,
                    last_name: paymentDetailsData?.last_name,
                    street_address: billingAddress?.street_address,
                    unit: billingAddress?.unit,
                    city: billingAddress?.city,
                    state: billingAddress?.state,
                    zip_code: billingAddress?.zip_code,
                    email: paymentDetailsData?.email,
                    phone_no: paymentDetailsData?.phone_no,
                }
            }
            USER_CHECKOUT(payload).then((res: any) => {
                if (res?.status === "fail") {
                    toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                    setLoading(false);
                } else {
                    if (res) {
                        if (res?.data?.payment_status === 'VALIDATION_FAILED') {
                            setShowErrorPopUp(true);
                            setLoading(false);
                            setPaymentError(res?.data?.validation_response?.Message || res?.data?.validation_response?.message);
                        } else {
                            if (res?.data?.payment_status === 'FAILED') {
                                setShowErrorPopUp(true);
                                setLoading(false);
                                setPaymentError(res?.data?.payment_response?.Message || res?.data?.payment_response?.message);
                            } else if (res?.data?.payment_status === 'SUCCESS') {
                                navigation(`/thank-you/${params?.user_uuid}/${params?.cart_uuid}/${params?.address_uuid}`);
                                setLoading(false);
                            } else {
                                setShowErrorPopUp(true);
                                setLoading(false);
                                setPaymentError('Payment Failed');
                            }
                        }
                    }
                }
            });
        } else {
            console.log("getting details error details :");
        }
    }

    const generateUniqueId = () => {
        return Math.random().toString(36).substr(2, 13);
    }

    return (
        <div className='primary-bg mt-xl-2'>
            <div className='container'>
                <div className='row mx-0'>
                    <Loader LoadingReducer={loading} />
                    <div className='col-md-12 pt-0 primary-bg mx-md-auto'>
                        <div className='row py-4 p-md-3 pt-xl-0'>
                            <div className='col-11 col-md-11 bg-white py-3 p-md-3 p-lg-5 mx-auto rounded-4 border-0 pt-xl-5 mt-xl-4'>
                                <div className='row'>
                                    <div className='col-lg-6 pe-xl-5'>
                                        <h4 className='fw-bold text-center text-md-start font-Oswald ls-1 fs-32'>Payment Information</h4>
                                        <div className='text-start fw-400 lh-sm'>Your vehicle registration renewel fee calculation is valid today
                                            only. Pay now and avoid DMV fines and penalties.</div>
                                        <form>
                                            <div className='row my-3 text-start'>
                                                <div className='col-md-6 my-1 pe-xl-0 mb-2 mb-lg-3'>
                                                    <label className='form-label fw-bold'>Cardholder First Name * </label>
                                                    <input type="text" name="first_name" autoComplete="cc-given-name" className='form-control custom-input' defaultValue={paymentDetailsData?.first_name} onChange={(e) => handleInput(e)} />
                                                    {formErrors.first_name && (<div className="text-danger text-start w-100">{formErrors.first_name} </div>)}
                                                </div>
                                                <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                                    <label className='form-label fw-bold'>Cardholder Last Name * </label>
                                                    <input type="text" name="last_name" autoComplete="cc-family-name" className='form-control custom-input' defaultValue={paymentDetailsData?.last_name} onChange={(e) => handleInput(e)} />
                                                    {formErrors.last_name && (<div className="text-danger text-start w-100">{formErrors.last_name} </div>)}
                                                </div>
                                                <div className='col-md-7 my-1 mb-2 mb-lg-3'>
                                                    <label className='form-label fw-bold'>Credit Card Number * </label>
                                                    <input type="text" name="credit_card_number" autoComplete="cc-number" maxLength={19} className='form-control custom-input' defaultValue={paymentDetailsData?.credit_card_number} onChange={(e) => handleInput(e)} />
                                                    {formErrors.credit_card_number && (<div className="text-danger text-start w-100">{formErrors.credit_card_number} </div>)}
                                                </div>
                                                <div className='col-md-5 my-1 ms-auto ms-md-0 d-flex justify-content-end mb-2 mb-lg-3'>
                                                    <img src={CreditCardImages} className='credit-card-image mt-md-4 pt-md-2 ms-auto' />
                                                </div>
                                                <div className='col-12 col-md-4 my-1 pe-xl-0 w-32 mb-2 mb-lg-3'>
                                                    <label className='form-label fw-bold'>Expiration Month * </label>
                                                    <input type="text" name="expiration_month" autoComplete="cc-exp-month" maxLength={2} className='form-control custom-input' defaultValue={paymentDetailsData?.expiration_month} onChange={(e) => handleInput(e)} />
                                                    {formErrors.expiration_month && (<div className="text-danger text-start w-100">{formErrors.expiration_month} </div>)}
                                                </div>
                                                <div className='col-12 col-md-3 my-1 pe-xl-0 w-30 mb-2 mb-lg-3'>
                                                    <label className='form-label fw-bold'>Expiration Year * </label>
                                                    <input type="text" maxLength={4} name="expiration_year" autoComplete="cc-exp-year" className='form-control custom-input' defaultValue={paymentDetailsData?.expiration_year} onChange={(e) => handleInput(e)} />
                                                    {formErrors.expiration_year && (<div className="text-danger text-start w-100">{formErrors.expiration_year} </div>)}
                                                </div>
                                                <div className='col-12 col-md-5 my-1 pe-0 w-36 mb-2 mb-lg-3'>
                                                    <label className='form-label fw-bold'>CVV (Security Code)*
                                                        <Tooltip text="The CVV (Card Verification Value) is a 3 or 4-digit number found on your credit or debit card. For Visa, MasterCard, and Discover cards, it's usually located on the back of the card near the signature area. For American Express cards, it can be found on the front, above the card number.">
                                                            <img className='ms-2' src={CVVInfo} alt="CVV" style={{ height: '16px' }} />
                                                        </Tooltip>
                                                    </label>
                                                    <input type="text" name="cvv" autoComplete="cc-csc" maxLength={3} className='form-control custom-input' defaultValue={paymentDetailsData?.cvv} onChange={(e) => handleInput(e)} />
                                                    {formErrors.cvv && (<div className="text-danger text-start w-100">{formErrors.cvv} </div>)}
                                                </div>

                                                <div className='col-12 text-center mt-3'>
                                                    <h6 className='fw-700 ps-4 mb-0'>Credit Card Billing Address</h6>
                                                    <div className='d-flex justify-content-center mt-1'>
                                                        <div className="form-check cursor-pointer p-0">
                                                            <input className="form-check-input cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                                                                checked={isCreditCardBillingAddressUseExisting === 'existing'}
                                                                onChange={(e) => handleRadioChange("existing")}
                                                            />
                                                            <label className="form-check-label cursor-pointer color_black" htmlFor="flexRadioDefault1">{`${paymentDetailsData?.full_name}`}</label>
                                                            <div className='ps-4 mt-1 ms-1 color_black'>
                                                                {/* <div className='text-start'>{`${paymentDetailsData?.first_name}  ${paymentDetailsData?.last_name}`}</div> */}
                                                                <div className='text-start'>{`${paymentDetailsData?.street_address}${paymentDetailsData?.unit ? "," : ''} ${paymentDetailsData?.unit}`}</div>
                                                                <div className='text-start'>{`${paymentDetailsData?.city}`}</div>
                                                                <div className='text-start'>{`${paymentDetailsData?.state}, ${paymentDetailsData?.zip_code}`}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-center mt-2 mb-5'>
                                                        <div className="form-check  cursor-pointer pl-2">
                                                            <input className="form-check-input cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                                                checked={isCreditCardBillingAddressUseExisting === 'new'}
                                                                onChange={(e) => handleRadioChange("new")}
                                                            />
                                                            <label className="form-check-label fw-500 cursor-pointer" htmlFor="flexRadioDefault2">Use a different address</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isCreditCardBillingAddressUseExisting === 'new' &&
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col-md-6 my-1 pe-xl-0 mb-2 mb-lg-3'>
                                                                <label className='form-label fw-bold'>Billing Street Address * </label>
                                                                <input type="text" name="street_address" autoComplete="address-line1" className='form-control custom-input' defaultValue={billingAddress?.street_address} onChange={(e) => handleInputAddress(e)} />
                                                                {billingAddressFormErrors.street_address && (<div className="text-danger text-start w-100">{billingAddressFormErrors.street_address} </div>)}
                                                            </div>
                                                            <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                                                <label className='form-label fw-bold'>Apt / Unit / Suite</label>
                                                                <input type="text" name="unit" autoComplete="address-line2" className='form-control custom-input' defaultValue={billingAddress?.unit} onChange={(e) => handleInputAddress(e)} />
                                                                {billingAddressFormErrors.unit && (<div className="text-danger text-start w-100">{billingAddressFormErrors.unit} </div>)}
                                                            </div>
                                                            <div className='col-md-12 my-1 mb-2 mb-lg-3'>
                                                                <label className='form-label fw-bold'>City * </label>
                                                                <input type="text" name="city" autoComplete="address-level2" className='form-control custom-input' defaultValue={billingAddress?.city} onChange={(e) => handleInputAddress(e)} />
                                                                {billingAddressFormErrors.city && (<div className="text-danger text-start w-100">{billingAddressFormErrors.city} </div>)}
                                                            </div>
                                                            <div className='col-md-6 my-1 pe-xl-0 mb-2 mb-lg-3'>
                                                                <label className='form-label fw-bold'>State * </label>
                                                                <select name="state" className='form-control custom-input' value={billingAddress?.state} onChange={(e) => handleInputAddress(e)} >
                                                                    <option value="" disabled>Select Sate</option>
                                                                    {Sates?.map((data: any) => { return <option key={data?.id} value={data?.id}>{data?.text}</option> })}
                                                                </select>
                                                                {/* <input type="text" name="state" className='form-control custom-input' defaultValue={billingAddress?.state} onChange={(e) => handleInput(e)} /> */}
                                                                {billingAddressFormErrors.state && (<div className="text-danger text-start w-100">{billingAddressFormErrors.state} </div>)}
                                                            </div>
                                                            <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                                                <label className='form-label fw-bold'>ZIP Code * </label>
                                                                <input type="text" name="zip_code" autoComplete="postal-code" maxLength={6} className='form-control custom-input' defaultValue={billingAddress?.zip_code} onChange={(e) => handleInputAddress(e)} />
                                                                {billingAddressFormErrors.zip_code && (<div className="text-danger text-start w-100">{billingAddressFormErrors.zip_code} </div>)}
                                                            </div>
                                                            {/* <div className='col-md-6 my-1 pe-xl-0'>
                                                        <label className='form-label fw-bold'>Email * </label>
                                                        <input type="text" name="email" autoComplete="email" className='form-control custom-input' defaultValue={paymentDetailsData?.email} onChange={(e) => handleInput(e)} />
                                                        {formErrors.email && (<div className="text-danger text-start w-100">{formErrors.email} </div>)}
                                                    </div>
                                                    <div className='col-md-6 my-1'>
                                                        <label className='form-label fw-bold'>Mobile Phone * </label>
                                                        <input type="text" name="phone_no" autoComplete="tel" className='form-control custom-input' defaultValue={paymentDetailsData?.phone_no} onChange={(e) => handleInput(e)} />
                                                        {formErrors.phone_no && (<div className="text-danger text-start w-100">{formErrors.phone_no} </div>)}
                                                    </div> */}
                                                        </div>
                                                    </div>}
                                                <div className='col-md-12 my-1'></div>
                                                <div className="form-check ms-3" style={{ height: '105px' }}>
                                                    <input className="form-check-input fw-bold cursor-pointer" type="checkbox" checked={paymentDetailsData?.renewal_reminders} name="renewal_reminders" id="flexRadioDefault3" onChange={(e) => handleCheckInput(e)} />
                                                    <label className="form-check-label ms-2 my-1 label-position fs-15 fw-400 custom-gray" htmlFor="flexRadioDefault3">Renewal Reminders - Receive paperless DMV registration reminders with a smart link to make your registration checkout fast, easy, and on-time.
                                                        Avoid late fees, registration citations, and expired tag tickets with reminders.</label>
                                                </div>
                                                <div className="form-check ms-3">
                                                    <input className="form-check-input fw-bold cursor-pointer" type="checkbox" checked={paymentDetailsData?.terms_and_conditions} name="terms_and_conditions" id="flexRadioDefault3" onChange={(e) => handleCheckInput(e)} />
                                                    <label className="form-check-label ms-2 my-1 label-position fs-15 fw-400 custom-gray" htmlFor="flexRadioDefault3">I agree to the <NavLink className={'fw-600 text-decoration-none'} to={'/terms-conditions'}>Terms of Use</NavLink> and Electronic Signature Consent. Registration payment transmitted to the DMV cannot be cancelled or reversed.</label>
                                                    {!paymentDetailsData?.terms_and_conditions && (<div className="text-danger text-start w-100 label-position ">Please accept terms of use </div>)}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='col-lg-6 ps-xl-3 order-2 order-lg-1 '>
                                        <ShippingAddressVehicleDetails vehicleData={userVehicleInfo} paymentDetails={true} onSubmit={() => onProcessOrder()} termsOfUseStatus={paymentDetailsData?.terms_and_conditions == false} />
                                    </div>
                                    <div className='col-md-12 d-none d-lg-block col-lg-6 order-1 order-lg-2 mb-5 mb-lg-0 d-xl-flex justify-content-between'>
                                        <div className='mb-4 mb-md-0'>
                                            <NavLink to={`/add-another-vehicle/${params?.user_uuid}/${params?.address_uuid}`}>
                                                <button className='px-3 fs-18 primary-bg primary-button_second fw-500 ms-md-3'>BACK</button>
                                            </NavLink>
                                        </div>
                                        <div className='ms-xl-5 process-order-position'>
                                            {/* <NavLink to={`/thank-you/${params?.user_uuid}/${params?.cart_uuid}`}> */}
                                            <button className='px-3 fs-18 rounded-1 border-0 primary-bg primary-button fw-500' disabled={paymentDetailsData?.terms_and_conditions == false} onClick={() => onProcessOrder()}>PROCESS ORDER</button>
                                            {/* </NavLink> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showErrorPopUp && (
                        <>
                            <div className={`modal modal-custom fade ${showErrorPopUp ? "show" : "false"}  ${showErrorPopUp ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content text-start">
                                        <div className="modal-header border-bottom-primary mx-4 px-0">
                                            <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Error</strong></h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowErrorPopUp(false)}></button>
                                        </div>
                                        <div className="modal-body pb-4">
                                            <div className="row px-2">
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <p className="f16 p-2"> <span className='fw-bold '>{paymentError}</span> </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='px-2 text-end'>
                                                <button type="button" className="px-3 fs-5 rounded border-0 primary-bg fw-bold " onClick={() => setShowErrorPopUp(false)}>OK</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div >
        </div >
    )
}
